import kernellLogo from '../../../assets/kernell-logo.svg'
import './style.css'

const HeaderLogo = () => {
  return(
    <>
      <div className="headerLogo">
        <img src={kernellLogo} alt="Está é a logo da Kernell Intelligence"/>
      </div>
    </>
  )
}

export default HeaderLogo
import './style.css'

const Button = () => {
  return(
    <>
      <div className="buttonBox">
        <button className='ctaButton'>Registrar-se</button>
      </div>  
    </>
  )
}

export default Button
import './App.css';
import Header from './components/header';
import Home from './components/home';

function App() {
  return (
    <div id="App">
      <Header></Header>
      <Home></Home>
    </div>
  );
}

export default App;

const menu_information = [
  {
    id: 'home_link',
    text: 'Inicio',
    href: 'homeBox'
  },
  {
    id: 'services_link',
    text: 'Serviços',
    href: 'myService'
  },
  {
    id: 'about_link',
    text: 'Sobre',
    href: 'myAbout'
  },
  {
    id: 'contact_link',
    text: 'Contato',
    href: 'myContact'
  },
]

export default menu_information
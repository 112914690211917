import { useState, useEffect } from 'react';

import HeaderLogo from "./logo";
import Nav from "./nav";
import './style.css';

const Header = () => {
  // State variables
  const [isActiveBurger, setIsActiveBurger] = useState(false);
  // Functions
  const handleActiveBurger = () => {
    setIsActiveBurger(!isActiveBurger);
  };

  const handleResize = () => {
    if (window.innerWidth > 1250) {
      setIsActiveBurger(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return(
    <>
      <header className={`${isActiveBurger ? 'isActiveHeader' : ''} header`}>
        <HeaderLogo />
        <Nav
          handleActiveBurger={handleActiveBurger}
          setIsActiveBurger={setIsActiveBurger} // Passing setIsActiveBurger here
        />
      </header>
    </>
  );
};

export default Header;

import './style.css'

const BurgerHeader = (props) => {
  return(
    <>
      <div className='burgerBox' onClick={props.handleActiveBurger}>
          <div className='burgerLine1'/>
          <div className='burgerLine2'/>
          <div className='burgerLine3'/>
      </div>
    </>
  )
}

export default BurgerHeader
import { useState, useEffect } from 'react';
import BurgerHeader from "./burger";
import './style.css';
import menuInformation from './data';
import Button from "./button/index";
import HeaderLogo from "../logo";

const Nav = (props) => {
  const [activeMenuItem, setActiveMenuItem] = useState('');

  const handleMenuItemClick = (item_menu) => {
    // Scroll to the element with the ID matching item_menu.href
    const element = document.getElementById(item_menu.href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setActiveMenuItem(item_menu.id);
      props.handleActiveBurger(false); // Close the burger menu
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      let currentSection = '';
      menuInformation.forEach(item => {
        const section = document.getElementById(item.href);
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
            currentSection = item.id;
          }
        }
      });
      setActiveMenuItem(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    // Initial call to set the active menu item based on initial scroll position
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return(
    <nav className="nav">
      <BurgerHeader handleActiveBurger={props.handleActiveBurger} />
      <div className={`navMenu ${props.isActiveBurger ? 'active' : ''}`}>
        <HeaderLogo />
        <ul>
          {menuInformation.map(item_menu => (
            <li 
              key={item_menu.id}
              className={activeMenuItem === item_menu.id ? 'active' : ''}
              onClick={() => handleMenuItemClick(item_menu)}
            >
              {item_menu.text}
            </li>
          ))}
        </ul>
        <Button />
      </div>
    </nav>
  );
};

export default Nav;

import Button from './button';
import './style.css';

function Home() {
  return (
    <div id='homeBox'>
      <div className="head"> 
        <h1>
          a <span className='blue'>Única consultoria e engenharia em redes</span> que sua empresa precisa.
        </h1>
        <p>
          Kernell Intelligence: <span className='blue'>Conduzindo sua operação à inovação com confiança e qualidade.</span>
        </p>
      </div>
      <div className="divider"></div>
      <div className="ctaBox">
        <p>Pronto para chegar até ao próximo nível?</p>
        <Button />
      </div>
    </div>
  );
}

export default Home;
